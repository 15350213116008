import './Moderator.scss';

import { useEffect, useState } from 'react';
import { FaCheck, FaRegTimesCircle } from 'react-icons/fa';
import styled from 'styled-components';

import DeleteModal from '../../components/DeleteModal/DeleteModal';
import SideBar from '../../components/SideBar/SideBar';
import TableColumn from '../../components/TableColumn/TableColumn';
import TopNav from '../../components/TopNav/TopNav';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import OrganizerRequests from '../OrganizerRequests/OrganizerRequests';
import MembershipRequests from '../MembershipRequests/MembershipRequests';
import OrganizerVenuesRequests from '../OrganizerVenuesRequests/OrganizerVenuesRequests';


const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const Tab = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 1vw 0;
  text-decoration: none;
  width: 50%;
  border-bottom: ${props => props.active ? '1px solid #a729f5' : 'none'};
  user-select: none;
  cursor: pointer;

  > p  {
    font-size: 14px;

  }
`;

function Moderators() {
    const navigate = useNavigate();

    const [mods, setMods] = useState([]);
    const [activeTab, setActiveTab] = useState('mods');
    const [displayMods, setDisplayMods] = useState(mods);

    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(
        Number(localStorage.getItem("ListCurrentPageModerators")) || 1
    );
    const records = 10;


    const [activeSearch, setActiveSearch] = useState(false);
    const [filderedMods, setFilteredMods] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [modToDelete, setModToDelete] = useState("");


    function handleDelete(id) {
        console.log("this is the id: ", id);
        const deleteMod = async () => {
            try {
                await api.delete(`/admin-users/${id}`);
                alert("Successfully deleted!");
                window.location.reload();
            } catch (error) {
                alert("could not delete!");
                setDeleteModal(false);
            }
        };
        deleteMod();
    }

    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState("null");

    useEffect(() => {
        const getMods = async (page) => {
            try {
                const response = await api.get(`/venue-requests`, {
                    params: {
                        page: page,
                        records: records,
                        sort_column: sortBy,
                        sort: sortDirection
                    },
                });

                const itensTableColumns = response.data.data.map((mod) => ({
                    Venue: mod.name,
                    Name: mod.contact_name || mod.name,
                    Email: mod.contact_email || mod.email,
                    "Phone Number": mod.contact_country_calling_code ? `${mod.contact_country_calling_code}${mod.contact_phone}` : mod.phone,
                    Status: mod.status,
                    _id: mod._id,
                    'Actions': (
                        <div style={{ cursor: 'pointer' }}>
                            <button
                                style={{ backgroundColor: 'transparent', border: '0' }}
                                onClick={() => {
                                    navigate(`/venue-requests/view/${mod._id}`)
                                }}
                            >
                                <MdOutlineRemoveRedEye size={30} color='rgba(167, 41, 245, 1)' />
                            </button>
                        </div>
                    ),
                }));

                setMods(itensTableColumns);
                setTotalPages(response.data.total)

            } catch (error) {
                console.log(`Couldn't get moderators. This is the error: ${error}`);
            }
        };
        getMods(currentPage);
    }, [navigate, currentPage, sortBy, sortDirection]);

    useEffect(() => {
        activeSearch ? setDisplayMods(filderedMods) : setDisplayMods(mods);
    }, [activeSearch, filderedMods, mods]);

    const columns = ["Venue", "Name", "Email", "Phone Number", "Status", "Actions"];

    const sortableColumns = ["Name", "Status"];

    const getStatusColor = (status) => {
        console.log(status)
        const capitalizedStatus = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
        switch (capitalizedStatus) {
            case 'Denied':
                return 'rgba(231, 76, 60, 0.1)';
            case 'Approved':
                return 'rgba(46, 204, 113, 0.1)';
            case 'Claiming':
                return 'rgba(441, 196, 15, 0.1)';
            case 'Pending':
                return 'rgba(231, 76, 60, 0.1)';
            case 'Accepted':
                return 'rgba(46, 204, 113, 0.1)';
            default:
                return 'inherit';
        }
    };


    const fetchAllData = async () => {
        try {
            const response = await api.get(`/venue-requests`, {
                params: {
                    records: 9999999999,
                },
            });

            console.log(response)

            return response.data.data.map((mod) => ({
                Venue: mod.name,
                Name: mod.contact_name || mod.name,
                Email: mod.contact_email || mod.email,
                "Phone Number": mod.phone,
                Status: mod.status,
                _id: mod._id,
            }));
        } catch (error) {
            console.log(`Error fetching all data: ${error}`);
            return [];
        }
    };


    const handlePageChange = (page) => {
        setCurrentPage(page);
        localStorage.setItem("ListCurrentPageModerators", page);
    };

    useEffect(() => {
        const storedPage = localStorage.getItem("ListCurrentPageModerators");

        setCurrentPage(Number(storedPage) || 1);

        const savedTab = localStorage.getItem("moderatorSelectedTab");
        if (savedTab) {
         setActiveTab(savedTab);
        }
    }, []);


    const sortColumnsMapping = {
        Name: 'contact_name',
        Status: 'status',
    };

    const handleSort = (columnName) => {
        const backendColumnName = sortColumnsMapping[columnName];
        if (sortBy === backendColumnName) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortBy(backendColumnName);
            setSortDirection("asc");
        }
    };
    

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        localStorage.setItem("moderatorSelectedTab", tab);
    };


    const RenderModerators = () => (
        <TableColumn
            title="Moderator"
            columns={columns}
            data={mods}
            sortableColumns={sortableColumns}
            hasExport={true}
            hasSearch={false}
            getStatusColor={getStatusColor}
            hasHeader={true}
            totalPages={totalPages}
            currentPage={currentPage}
            fetchAllData={fetchAllData}
            onPageChange={handlePageChange}

            onSort={handleSort}
        />
    )



    return (
        <main>
            <TopNav />
            <div className="main-container">
                <SideBar />
                {deleteModal && (
                    <DeleteModal
                        handleDelete={handleDelete}
                        setDeleteModal={setDeleteModal}
                        modToDelete={modToDelete}
                        tableName={"MODERATORS"}
                    />
                )}
                <article
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        overflow: "hidden"
                    }}
                >
                    
                <section className="moderators">
                    <div>
                        <TabsContainer>
                            <Tab
                                active={activeTab === 'mods'}
                                onClick={() => handleTabClick('mods')}
                            >
                            <p>Moderators</p>
                            </Tab>
                            <Tab
                                active={activeTab === 'organizer-req'}
                                onClick={() => handleTabClick('organizer-req')}
                            >
                            <p>Organizer Requests</p>
                            </Tab>
                            <Tab
                                active={activeTab === 'organizer-venues-req'}
                                onClick={() => handleTabClick('organizer-venues-req')}
                            >
                            <p>New Venues Requests</p>
                            </Tab>
                            <Tab
                                active={activeTab === 'membership-req'}
                                onClick={() => handleTabClick('membership-req')}
                            >
                                <p>Membership Requests</p>
                            </Tab>
                        </TabsContainer>
                    </div>
                    
                    
                    {activeTab === 'mods' && <RenderModerators />}
                    {activeTab === 'organizer-req' && <OrganizerRequests />}
                    {activeTab === 'organizer-venues-req' && <OrganizerVenuesRequests />}
                    {activeTab === 'membership-req' && <MembershipRequests />} 
                    
                </section>
                    

                </article>
            </div>
        </main>
    );
}

export default Moderators;
