import './Sponsorship.scss';
import { useEffect, useState } from 'react';
import SideBar from '../../components/SideBar/SideBar';
import TopNav from '../../components/TopNav/TopNav';
import api from "../../services/api";
import TableColumn from '../../components/TableColumn/TableColumn';
import { useNavigate } from 'react-router-dom';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaRegTimesCircle, FaCheck } from "react-icons/fa";

function Sponsorship() {
    const navigate = useNavigate()
    const [events, setEvents] = useState([])


    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const records = 10;

    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState("null");


    async function getEvents(page) {
        try {
            const response = await api.get("/spotlights/requests", {
                params: {
                    page: page + 1,
                    records: records,
                    sort_column: sortBy,
                    sort: sortDirection,
                },
            });

            const createdAt = new Date();
            const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            const formattedDate = createdAt.toLocaleDateString('en-US', options);

            const itensTableColumns = response.data.data.map((item) => ({


                "": (
                    <div>
                        <MdOutlineRemoveRedEye
                            onClick={() => navigate(`/events/edit/${item.event_id}`)}
                            size={30} color='rgba(167, 41, 245, 1)' style={{ cursor: "pointer" }} />
                    </div>
                ),
                Time: formattedDate,
                Event: item.event_name,
                Business: item.business_name,
                Amount: '$100',
                Status: item.status,
                'Accept/Decline': (
                    <div style={{ cursor: 'pointer' }}>
                        {item.status === 'Active' ? (
                            <FaRegTimesCircle
                                size={20}
                                color='rgba(247, 6, 6, 1)'
                                onClick={() => handleAcceptSpotlight(item._id)}
                            />
                        ) : (

                            <FaCheck
                                size={20}
                                color="rgba(5, 229, 108, 1)"
                                onClick={() => handleDeclineSpotlight(item._id)}
                            />
                        )}
                    </div>
                ), id: item._id
            }))


            setTotalPages(response.data.total);
            setEvents(itensTableColumns);
        } catch (error) {
            console.log(`events get request didn't work. error: ${error}`);
        }
    }

    const reloadEvents = async () => {
        try {
            const { data } = await api.get("/spotlights/requests");
            const itensTableColumns = [];

            data.data.forEach(event => {
                const createdAt = new Date(event.created_at);
                const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
                const formattedDate = createdAt.toLocaleDateString('en-US', options);

                const item = {
                    "": (
                        <div>
                            <MdOutlineRemoveRedEye
                                onClick={() => navigate(`/events/edit/${event.event_id}`)}
                                size={30} color='rgba(167, 41, 245, 1)' style={{ cursor: "pointer" }} />
                        </div>
                    ),
                    Time: formattedDate,
                    Event: event.event_name,
                    Business: event.business_name,
                    Amount: '$100',
                    Status: event.status,
                    'Accept/Decline': (
                        <div style={{ cursor: 'pointer' }}>
                            {event.canceled ? (
                                <FaCheck
                                    size={20}
                                    color="rgba(5, 229, 108, 1)"
                                    onClick={() => handleDeclineSpotlight(event._id)}
                                />
                            ) : (
                                <FaRegTimesCircle
                                    size={20}
                                    color='rgba(247, 6, 6, 1)'
                                    onClick={() => handleAcceptSpotlight(event._id)}
                                />
                            )}
                        </div>
                    ), id: event._id
                };
                itensTableColumns.push(item);
            });

            setEvents(itensTableColumns);
        } catch (error) {
            console.log(`events get request didn't work. error: ${error}`);
        }
    }

    useEffect(() => {
        getEvents(currentPage);
    }, [currentPage, sortBy, sortDirection]);

    const handleAcceptSpotlight = async (spotlightId) => {
        try {
            await api.patch(`/spotlights/decline/${spotlightId}`)
            reloadEvents();
        } catch (error) {
            console.log(``)
        }
    }

    const handleDeclineSpotlight = async (spotlightId) => {
        try {
            await api.patch(`/spotlights/accept/${spotlightId}`)
            reloadEvents();
        } catch (error) {
            console.log(``)
        }
    }

    const columns = [
        "",
        "Time",
        "Event",
        "Business",
        "Amount",
        "Status",
        "Accept/Decline",
    ];

    const sortableColumns = ["Event", "Time", 'Status'];

    const getStatusColor = (status) => {
        console.log(status)
        switch (status) {
            case 'Declined':
                return 'rgba(231, 76, 60, 0.1)';
            case 'Active':
                return 'rgba(46, 204, 113, 0.1)';
            case 'New':
                return 'rgba(241, 196, 15, 0.1)';
            case 'Expired':
                return 'rgba(231, 76, 60, 0.1)';
            default:
                return 'inherit';
        }
    };


    const sortColumnsMapping = {
        Event: "event_name",
        Time: "createdAt",
        Status: "status",
    };

    const handleSort = (columnName) => {
        const backendColumnName = sortColumnsMapping[columnName];
        if (sortBy === backendColumnName) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortBy(backendColumnName);
            setSortDirection("asc");
        }
    };

    return (
        <main>
            <TopNav />
            <div className='main-container'>
                <SideBar />
                <article style={{ width: '100%', display: 'flex', justifyContent: 'center', overflow: "hidden" }}>
                    <section className='sponsorship'>
                        <TableColumn
                            title="Guestlist requests"
                            columns={columns}
                            data={events}
                            sortableColumns={sortableColumns}
                            hasExport={false}
                            hasSearch={false}
                            getStatusColor={getStatusColor}

                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={setCurrentPage}
                            records={records}

                            onSort={handleSort}
                        />
                    </section>
                </article>
            </div>
        </main>
    )
}

export default Sponsorship;
