import './AddVenue.scss';
import 'react-toastify/dist/ReactToastify.css';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { Link, useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import * as Yup from 'yup';

import checkMark from '../../assets/images/icons/check-mark.svg';
import chevronLeft from '../../assets/images/icons/chevron-left.svg';
import { HoursOfOperation } from '../../components/HoursOfOperation';
import Image from '../../components/Image';
import InputForm from '../../components/InputForm/InputForm';
import SelectForm from '../../components/SelectForm/SelectForm';
import SideBar from '../../components/SideBar/SideBar';
import TopNav from '../../components/TopNav/TopNav';
import api from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AddVenue = () => {
  const navigate = useNavigate();

  const [additionalHours, setAdditionalHours] = useState([]);
  const [updatedHour, setUpdatedHour] = useState();
  const [loading, setLoading] = useState(false);

  const [updatedName, setUpdatedName] = useState("");
  const [updatedCat, setUpdatedCat] = useState([]);
  const [updatedAddress, setUpdatedAddress] = useState("");

  const [updatedUrl, setUpdatedUrl] = useState("");
  const [updatedEmail, setUpdatedEmail] = useState("");
  // const [updatedPrice, setUpdatedPrice] = useState("");
  const [updatedAbout, setUpdatedAbout] = useState("");
  const [updatedPhone, setUpdatedPhone] = useState("");
  const [updatedLat, setUpdatedLat] = useState("");
  const [updatedLong, setUpdatedLong] = useState("");
  const [updatedDress, setUpdatedDress] = useState();
  const [updatedMusic, setUpdatedMusic] = useState([]);

  const [updateState, setUpdateState] = useState("");
  const [updateCity, setUpdateCity] = useState("");
  const [updateCountry, setUpdateCountry] = useState("");
  const [updateMapIcon, setUpdateMapIcon] = useState("");
  const [updatedLocation, setUpdatedLocation] = useState("");
  const [locationsOptions, setLocationOptions] = useState([]);

  const [updateSpecials, setUpdateSpecials] = useState("");

  const [venueImages, setVenueImages] = useState([{ id: v4() }]);

  const [placeId, setPlaceId] = useState();

  const [errors, setErrors] = useState({});

  const handleImageAdd = useCallback((event) => {
    const file = event.target.files[0];

    const image = {
      id: v4(),
      file,
    };

    setVenueImages((oldState) => [...oldState, image]);
  }, []);

  const handleImageRemove = useCallback((id) => {
    setVenueImages((oldState) => {
      const updatedState = oldState.filter((img) => img.id !== id);

      return updatedState;
    });
  }, []);


  const validationSchema = Yup.object().shape({
    updatedName: Yup.string().required("Name is Required"),
    updatedCat: Yup.array().of(Yup.string()).min(1, "Select at least one category"),
    updatedAddress: Yup.string().required("Select a location"),
    updatedPhone: Yup.string().required("Phone is Required"),
    updatedUrl: Yup.string().required("Website is Required"),
    updatedEmail: Yup.string().required("Email is Required"),
    updatedAbout: Yup.string().required("About is Required"),
    // updateSpecials: Yup.string().required("Specials is Required"),
    updatedDress: Yup.string().required("Select dress code"),
    updatedMusic: Yup.array().of(Yup.string()).min(1, "Select at least one music type"),
  });

  const handleAddVenue = async (event) => {
    event.preventDefault();

    // Parse hours from additionalHours
    const parsedHours = additionalHours.reduce((acc, cur) => {
      const { day, hours } = cur;
      acc[day] = hours;
      return acc;
    }, {});

    if (updatedHour) parsedHours[updatedHour.day] = updatedHour.hours;

    try {
      // Yup validation schema
      await validationSchema.validate(
          {
            updatedName,
            updatedCat,
            updatedAddress,
            updatedPhone,
            updatedUrl,
            updatedEmail,
            updatedAbout,
            updatedDress,
            updatedMusic,
          },
          { abortEarly: false }
      );

      // Construct newVenue object
      const newVenue = {
        name: updatedName,
        tags: updatedCat,
        address: updatedAddress,
        hours: parsedHours,
        url: updatedUrl,
        email: updatedEmail,
        phone: updatedPhone,
        lat: updatedLat,
        lng: updatedLong,
        dress: updatedDress,
        music: updatedMusic,
        description: updatedAbout,
        state: updateState,
        city: updateCity,
        country: updateCountry,
        // marker_icon: updateMapIcon,
        specials: updateSpecials,
        local: updatedLocation,
        place_id: placeId,
      };

      // Function to add the new venue
      const addVenue = async () => {
        try {
          setLoading(true);
          const { data: addedVenue } = await api.post("/venues", newVenue);

          // Handle venue image upload
          const formData = new FormData();
          venueImages.forEach((img) => {
            if (img.file) formData.append("images", img.file);
          });

          await api.post(`/venues/images/${addedVenue._id}`, formData);

          setLoading(false);
          toast.success("New Venue added successfully!");
          navigate("/business");
        } catch (error) {
          console.error("Error adding venue or uploading images: ", error);

          setLoading(false);
          // Handle specific error responses
          if (error.response && error.response.data?.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error("An error occurred while adding the venue.");
          }
        }
      };

      // Call the addVenue function
      await addVenue();

    } catch (error) {
      // Handle Yup validation errors
      if (error instanceof Yup.ValidationError) {
        const validationErrors = error.inner.reduce((acc, err) => {
          acc[err.path] = err.message;
          return acc;
        }, {});
        setErrors(validationErrors);
        toast.error(`Validation Error: ${error.message}`);
      } else {
        console.error("An error occurred: ", error);
        toast.error(`Error adding new Venue. Please try again, ${error.message}`);
      }
    }
  };

  const markeIcon = [
    { label: "Bar", value: "bar" },
    { label: "Club", value: "club" },
    { label: "Lounge", value: "lounge" },
  ];


  const handleCategoryChange = (selectedOptions) => {
    const selectedValues = Array.isArray(selectedOptions)
      ? selectedOptions.map((option) => option.value)
      : [];

    setUpdatedCat(selectedValues);
  };


  const handleDressChange = (selectedOption) => {
    setUpdatedDress(selectedOption ? selectedOption.value.trim() : ""); // Set updatedDress directly to the selected value
  };
  const handleMapIcon = (selectedOptions) => {
    setUpdateMapIcon(selectedOptions ? selectedOptions.value : "");
  };

  const handleMusicChange = (selectedOption) => {
    const selectedValues = Array.isArray(selectedOption)
      ? selectedOption.map((option) => option.value)
      : [];

    setUpdatedMusic(selectedValues);
  };


  const handleSelect = async (result) => {
    const placeDetails = await geocodeByPlaceId(result.value.place_id);
    const {
      address_components,
      formatted_address,
      geometry: {
        location: { lat, lng },
      },
    } = placeDetails[0];

    const city = address_components.find((address) =>
      address.types.some((type) => type === "administrative_area_level_2")
    );
    const state = address_components.find((address) =>
      address.types.some((type) => type === "administrative_area_level_1")
    );
    const country = address_components.find((address) =>
      address.types.some((type) => type === "country")
    );

    if (city) setUpdateCity(city.long_name);
    if (state) setUpdateState(state.short_name);
    if (country) setUpdateCountry(country.short_name);

    setUpdatedAddress(formatted_address);
    setUpdatedLat(lat);
    setUpdatedLong(lng);
    setPlaceId(result.value.place_id);
  };

  const googlePlacesTypesToFetch = ["establishment"];

  const [, setFilters] = useState();

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [dressCodeOptions, setDressCodeOptions] = useState([]);
  const [musicOptions, setMusicOptions] = useState([]);

  useEffect(() => {
    async function getFilters() {
      try {
        const response = await api.get('filters')

        const tags = response.data.tags;
        const dressCode = response.data.dress_code
        const music = response.data.music;
        const locations = response.data.location;

        const formattedTags = tags.map(tag => ({ value: tag, label: tag }));
        const formattedDressCode = dressCode.map(tag => ({ value: tag, label: tag }));
        const formattedMusic = music.map(tag => ({ value: tag, label: tag }));
        const locationOptions = locations.map(tag => ({ value: tag, label: tag }));

        setCategoryOptions(formattedTags);
        setDressCodeOptions(formattedDressCode);
        setMusicOptions(formattedMusic)
        setLocationOptions(locationOptions);
        setFilters(response.data)
      } catch (err) {
        console.log(err)
      }
    }

    getFilters()
  }, [])

  return (
    <main>
      <TopNav />
      <div className="main-container">
        <SideBar />
        <article
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <section className="add-venue">
            <div className="add-venue__top">
              <Link to="/business">
                <img
                  src={chevronLeft}
                  className="add-venue__top-icon"
                  alt="chevron pointing left"
                ></img>
              </Link>
              <h1 className="add-venue__top-heading">Adding new business</h1>
            </div>

            <form onSubmit={handleAddVenue} className="add-venue__form">
              <section className="add-venue__form--left">
                <div className="add-venue__form-block">
                  <div className="add-venue__form-block-group">
                    <InputForm
                      placeholder="Name"
                      label="Display Name"
                      type="text"
                      id="venueName"
                      name="name"
                      value={updatedName}
                      onChange={(event) => setUpdatedName(event.target.value)}
                      error={errors.updatedName}
                    />
                  </div>

                  <div className="add-venue__form-block-group">
                    <SelectForm
                      label="Category"
                      isMulti
                      options={categoryOptions}
                      value={categoryOptions.filter(option =>
                        updatedCat.includes(option.value)
                      )}
                      onChange={handleCategoryChange}
                      error={errors.updatedCat}
                    />
                  </div>
                </div>

                <div
                  className="add-venue__form-block"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="add-venue__form-block-group">
                    <label style={{ padding: "0.5vw 16px", display: "flex", gap: "10px", alignItems: " center", fontSize: "14px" }} >Address (enter name of venue)

                      <span className='error-message'>{errors.updatedAddress}</span>

                    </label>
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyAvEaAqsbyEr0iyfxqddZD5KsM1tnfU6Wc"
                      autocompletionRequest={{
                        types: googlePlacesTypesToFetch,
                      }}
                      selectProps={{
                        onChange: handleSelect,

                        styles: {
                          control: (provided) => ({
                            ...provided,
                            background: "rgba(255, 255, 255, 0.1)",
                            border: "none",
                            padding: "4px 16px",
                            borderRadius: 8,
                            minHeight: "54px"
                          }),
                          input: (provided) => ({
                            ...provided,
                            color: "#fff",
                          }),
                          menuList: (provided) => ({
                            ...provided,
                            color: "black",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: "#fff",
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            padding: "0px"
                          }),
                        },
                      }}
                    />
                  </div>

                  {/* <div className="add-venue__form-block-group">
                    <label style={{ padding: "0.5vw 16px" }}>
                      Hours of operation
                    </label>

                    <div className="add-venue__hours-of-operation">
                      <div className="add-venue__hours-of-operation__first-hour">
                        <HoursOfOperation
                          excludeDays={omitDays}
                          onChangeDay={({ value }) => {
                            setUpdatedHour((oldState) => {
                              if (!oldState?.hours) {
                                return { day: value };
                              }

                              return { ...oldState, day: value };
                            });
                          }}
                          onChangeTime={({ value, type }) => {
                            setUpdatedHour((oldState) => {
                              if (!oldState?.day) {
                                return { hours: { [type]: value } };
                              }

                              const { day, hours } = oldState;

                              return {
                                day,
                                hours: { ...hours, [type]: value },
                              };
                            });
                          }}
                        />

                        {additionalHours.length < 6 && (
                          <button type="button" onClick={handleAddHours}>
                            +
                          </button>
                        )}
                      </div>

                      {additionalHours.map((hour, index) => {
                        return (
                          <div
                            className="add-venue__hours-of-operation__additional-hours"
                            key={index}
                          >
                            <HoursOfOperation
                              key={index}
                              excludeDays={omitDays}
                              onChangeDay={(event) =>
                                handleOnDayChange(index, event)
                              }
                              onChangeTime={(event) =>
                                handleOnTimeChange(index, event)
                              }
                              defaultValues={hour}
                            />

                            <button
                              type="button"
                              onClick={() => handleRemoveHours(index)}
                            >
                              -
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                </div>

                <div className="add-venue__form-block">
                  <div className="add-venue__form-block-group">
                    <InputForm
                      label="Phone"
                      type="text"
                      id="venuePhone"
                      name="phone"
                      value={updatedPhone}
                      onChange={(event) => setUpdatedPhone(event.target.value)}
                      placeholder={"Enter phone"}
                      error={errors.updatedPhone}

                    />
                  </div>

                  <div className="add-venue__form-block-group">
                    <InputForm
                      label="Website"
                      type="text"
                      id="venueSite"
                      name="url"
                      value={updatedUrl}
                      onChange={(event) => setUpdatedUrl(event.target.value)}
                      placeholder={"Enter website"}
                      error={errors.updatedUrl}

                    />
                  </div>
                </div>

                <div className="add-venue__form-block">
                  <div className="add-venue__form-block-group">

                    <InputForm
                        label=" E-mail"
                        type="email"
                        id="venueEmail"
                        name="email"
                        value={updatedEmail}
                        onChange={(event) => setUpdatedEmail(event.target.value)}
                        placeholder={"Enter e-mail"}
                        error={errors.updatedEmail}


                    />
                  </div>

                  {/* <div className="add-venue__form-block-group">
                    <InputForm
                      label="Price"
                      type="text"
                      id="venuePrice"
                      name="price"
                      value={updatedPrice}
                      onChange={(event) => setUpdatedPrice(event.target.value)}
                      placeholder="Example: $20 - $50"
                      error={errors.updatedPrice}

                    />
                  </div> */}

                  <div className="add-venue__form-block-group">
                    <SelectForm
                        error={errors.updatedDress}
                        label="Dress-code"
                        name="dress"
                        options={dressCodeOptions}
                        value={dressCodeOptions.find(option => option.value === updatedDress) || null}
                        onChange={handleDressChange}
                        getOptionValue={(option) => option.value}
                    />
                  </div>

                </div>

                <div className="add-venue__form-block">


                  <div className="add-venue__form-block-group">
                    <SelectForm
                        error={errors.updatedMusic}

                        label="Music"
                        isMulti
                        options={musicOptions}
                        value={musicOptions.filter((option) =>
                            updatedMusic.includes(option.value)
                      )}
                      onChange={handleMusicChange}
                    />
                  </div>

                  <div className="add-venue__form-block-group">
                    <SelectForm
                      error={errors.updatedMusic}
                      label="Location"
                      options={locationsOptions}
                      value={locationsOptions.find(opt => opt.value === updatedLocation)}
                      onChange={option => {
                        if (option) setUpdatedLocation(option.value);
                      }}
                    />
                  </div>

                  {/* <div className="add-venue__form-block-group">
                    <SelectForm
                      error={errors.updateMapIcon}

                      label="Map Icon"
                      name="mapIcon"
                      options={markeIcon}

                      value={
                        updateMapIcon
                          ? markeIcon.filter((option) =>
                            updateMapIcon.includes(option.value)
                          )
                          : []
                      }
                      onChange={handleMapIcon}
                      getOptionValue={(option) => option.value}
                    />
                  </div> */}
                </div>





                <div
                  className="add-venue__form-block"
                  style={{ marginBottom: 20 }}
                >
                  {venueImages.map((img) => {
                    return (
                      <Image
                        key={img.id}
                        onAdd={handleImageAdd}
                        onRemove={() => handleImageRemove(img.id)}
                      />
                    );
                  })}
                </div>


                <div className="add-venue__form-block">
                  <div
                    className="add-venue__form-block-group"
                    style={{ width: "100%" }}
                  >
                    <label
                      htmlFor="venueAbout"
                      className="venue-info__form-group-field-label"
                    >
                      About
                      <span className='error-message'>{errors.updatedAbout}</span>
                    </label>
                    <textarea
                      placeholder='Description of the venue'
                      rows={4}
                      id="venueAbout"
                      name="description"
                      className="venue-info__form-group-about-input"
                      value={updatedAbout}
                      onChange={(event) => setUpdatedAbout(event.target.value)}
                    // placeholder={specVenue.about || "Enter description"}
                    />
                  </div>
                </div>

                <div className="add-venue__form-block">
                  <div
                    className="add-venue__form-block-group"
                    style={{ width: "100%" }}
                  >
                    <label
                      htmlFor="venueAbout"
                      className="venue-info__form-group-field-label"
                    >
                      More Information (Specials)
                      <span className='error-message'>{errors.updateSpecials}</span>
                    </label>
                    <textarea
                      placeholder='Description of the venue'
                      rows={4}
                      id="venueAbout"
                      name="description"
                      className="venue-info__form-group-about-input"
                      value={updateSpecials}
                      onChange={(event) => setUpdateSpecials(event.target.value)}
                    // placeholder={specVenue.about || "Enter description"}
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="add-user__form-button"
                  disabled={loading}
                  style={{ opacity: loading ? 0.5 : 1 }}
                >
                  <p className="add-user__form-button-text">
                    {loading ? 'Sending Information...' : 'Save Information'}
                  </p>
                  <img src={checkMark} alt="a checkmark" />
                </button>

              </section>
            </form>
          </section>
        </article>
      </div>
    </main>
  );
};

export default AddVenue;
