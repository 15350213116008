import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { TailSpin } from "react-loader-spinner";
import "./styles.scss";
import TopNav from "../../components/TopNav/TopNav";
import SideBar from "../../components/SideBar/SideBar";
import chevronLeft from "../../assets/images/icons/chevron-left.svg";
import VenueInformation from "../../components/VenueInformation/VenueInformation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingPage from "../../components/LoadingPage/LoadingPage";

export default function AcceptVenueRequest() {
  const { venueRequestId } = useParams();
  const navigate = useNavigate();

  const [venueRequest, setVenueRequest] = useState();
  const [venue, setVenue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    async function loadRequest() {
      const { data: venueRequest } = await api.get(
        `/venue-requests/${venueRequestId}`
      );

      setVenueRequest(venueRequest);
      setVenue(venueRequest.venue);

      setIsLoading(false);
    }

    loadRequest();
  }, [venueRequestId]);

  const handleAcceptRequest = useCallback(async () => {
    try {
      await api.put(`/venue-requests/accept/${venueRequest._id}`, {});

      toast.success("This request was accepted!");

      navigate("/moderators");
    } catch (err) {
      console.log(err);
      toast.error(
        "An error ocurred, try again later. If persists, contact system administrator!"
      );
    }
  }, [venueRequest, navigate]);

  const handleDenyRequest = useCallback(async () => {
    try {
      await api.put(`/venue-requests/deny/${venueRequest._id}`, {});

      toast.success("This request was rejected!");

      navigate("/moderators");
    } catch (err) {
      console.log(err);
      toast.error(
        "An error ocurred, try again later. If persists, contact system administrator!"
      );
    }
  }, [venueRequest, navigate]);

  if (!venueRequest) {
    return (
      <main>
        <TopNav />
        <div className="main-container">
          <SideBar />
          <div id="venue-request__container">
            <TailSpin width={30} height={30} color="#fff" />
          </div>
        </div>
      </main>
    );
  }

  return (
    <main>
      <TopNav />
      <div className="main-container">
        <SideBar />

        <div className="venue-request">
          <div className="venue-request__top">
            <div className="venue-request__top-left">
              <img
                src={chevronLeft}
                className="venue-request__top-left-icon"
                onClick={() => navigate(-1)}
                alt=""
              />
              <h1 className="venue-request__top-left-heading">
                Viewing venue request
              </h1>
            </div>
          </div>

          {isLoading ? (
            <>
              <LoadingPage />
            </>
          ) : (
            <>
              {venue ? (
                <>
                  <VenueInformation specVenue={venue} view />
                </>
              ) : (
                <>
                  <VenueInformation specVenue={venueRequest} view />
                </>
              )}

              <div className="venue-request__button-container">
                <button
                  className="venue-request__button-container__accept"
                  onClick={handleAcceptRequest}
                >
                  Accept
                </button>

                <button
                  className="venue-request__button-container__deny"
                  onClick={handleDenyRequest}
                >
                  Deny
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </main>
  );
}
