import "./MembershipRequestDetails.scss";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import chevronLeft from "../../assets/images/icons/chevron-left.svg";
import upload from "../../assets/images/icons/upload.svg";
import SideBar from "../../components/SideBar/SideBar";
import TopNav from "../../components/TopNav/TopNav";
import api from "../../services/api";
import CalculateAge from "../../utils/CalculateAge";
import ReactRating from "react-rating";
import { CiLocationOn, CiClock2 } from "react-icons/ci";
import { SlShare } from "react-icons/sl";
import { Link } from "react-router-dom";
import defaultPhoto from "../../assets/images/icons/profileDefault.png";
import { toast } from "react-toastify";

function MembershipRequestDetails() {
  const { userID } = useParams();
  const { state } = useLocation();
  const requestData = state?.requestData;
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState([]);
  const [userCheckIns, setUserCheckIns] = useState([]);
  const [pastEventsList, setPastEventsList] = useState([]);

  const [activeTab, setActiveTab] = useState('reqDetails')

  const headings = ["Check in", "Date", "Time", "Ratings", "Comments"];

  const birthday = CalculateAge(userInfo?.birthday);

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await api.get(`/users/${userID}`);

        // setUserPhoto(`data:image/jpeg;base64,${response.data.photo}`);
        setUserCheckIns(response.data.check_ins);
        setUserInfo(response.data.user);
      } catch (err) {
        console.log(`error fetching user. this is the error: ${err}`);
      }
    };

    const getPastEvents = async () => {
      try {
        const response = await api.get(`/events/users/${userID}/past`);

        setPastEventsList(response.data);
      } catch (err) {
        console.log(
          `error fetching list past Events. this is the erro: ${err}`
        );
      }
    };

    getPastEvents();
    getUser();
  }, [userID]);

  useEffect(() => {
    if (!(requestData)) {
      navigate('/moderators')
    }
  }, [requestData])

  function handleSubNavClick(tab) {
    setActiveTab(tab);
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    return date.toLocaleTimeString("en-US", options);
  };

  const renderRatingStars = (rating) => {
    return (
      <ReactRating
        readonly
        initialRating={rating}
        emptySymbol={
          <span style={{ color: "rgb(167, 41, 245)", fontSize: "24px" }}>
            &#9734;
          </span>
        }
        fullSymbol={
          <span style={{ color: "rgb(167, 41, 245)", fontSize: "24px" }}>
            &#9733;
          </span>
        }
        fractions={2}
      />
    );
  };

  const handleAcceptRequest = async () => {
    if (window.confirm("Are you sure you want to approve this request?")) {
      try {
        await api.post(`/membership/review/${requestData._id}`, {
          reviewStatus: 'approved',
          reasonForDisapproval: ''
        });

        toast.success("This request was accepted!");
  
        navigate("/moderators");
      } catch (error) {
        console.log('Error Processing Request ==> ', error);
        toast.error(
          "An error occurred, try again later. If persists, contact system administrator!"
        );
      }
      
    }
  }

  const handleDenyRequest = async () => {
    let reason = prompt("Are you sure you want to deny this request? \n\n Please provide a reason below");
    if (!(reason == null)) {
      try {
        await api.post(`/membership/review/${requestData._id}`, {
          reviewStatus: 'disapproved',
          reasonForDisapproval: (reason || '')
        });

        toast.success("This request was rejected!");
  
        navigate("/moderators");

      } catch (error) {
        console.log('Error Processing Request ==> ', error);
        toast.error(
          "An error occurred, try again later. If persists, contact system administrator!"
        );
      }
    }
  }

  const formatHours = (hours) => {
    const fromHours = Math.floor(hours.from / 60);
    const fromMinutes = String(hours.from % 60).padStart(2, "0");
    const toHours = Math.floor(hours.to / 60);
    const toMinutes = String(hours.to % 60).padStart(2, "0");
    return `${fromHours}:${fromMinutes} - ${toHours}:${toMinutes}`;
  };

  return (
    <main>
      <TopNav />

      <div className="main-container">
        <SideBar />
        <article
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <section className="user-profile">
            <div className="user-profile__top">
              <div className="user-profile__top-left">
                <img
                  src={chevronLeft}
                  onClick={() => navigate(-1)}
                  className="user-profile__top-left-icon"
                  alt="arrow head poining left"
                ></img>
                <h1 className="user-profile__top-left-heading">Membership Request</h1>
              </div>

              <div className="venue-request__button-container">
                {
                  (requestData?.reviewStatus === 'pending') ? (
                    <>
                      <button
                        className="venue-request__button-container__accept"
                        onClick={handleAcceptRequest}
                      >
                        Accept
                      </button>

                      <button
                        className="venue-request__button-container__deny"
                        onClick={handleDenyRequest}
                      >
                        Deny
                      </button>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          color: (requestData?.reviewStatus === 'approved') ? 'rgba(46, 204, 113, 1)' : 'rgba(255,0,0,0.75)'
                        }}
                      >{requestData?.reviewStatus}{'!'}</div>
                    </>
                  )
                }
              </div>
            </div>

            <section className="user-profile__user">
              <img
                className="user-profile__user-avatar"
                src={userInfo.photo_url ?? defaultPhoto}
                alt={`${userInfo.firstName} ${userInfo.lastName} avatar`}
              />

              <div className="user-profile__user-writing">
                <h2 className="user-profile__user-writing-heading">
                  {userInfo?.firstName} {userInfo?.lastName}
                </h2>
                <p className="user-profile__user-writing-text">
                  {birthday}, {userInfo?.location}
                </p>
                <p className="user-profile__user-writing-text">
                  {userInfo?.ig && `@${userInfo.ig}`}
                </p>
              </div>
            </section>

            <section className="user-profile__tabs">
              <div
                onClick={() => handleSubNavClick("reqDetails")}
                className={
                  (activeTab === 'reqDetails')
                    ? "user-profile__tabs-tab user-profile__tabs-tab--active"
                    : "user-profile__tabs-tab"
                }
              >
                Request Details
              </div>
              <div
                onClick={() => handleSubNavClick("favEvents")}
                className={
                  (activeTab === 'favEvents')
                    ? "user-profile__tabs-tab user-profile__tabs-tab--active"
                    : "user-profile__tabs-tab"
                }
              >
                Favorite Events
              </div>
              <div
                onClick={() => handleSubNavClick("pastEvents")}
                className={
                  (activeTab === 'pastEvents')
                    ? "user-profile__tabs-tab user-profile__tabs-tab--active"
                    : "user-profile__tabs-tab"
                }
              >
                Past Events
              </div>
              <div
                onClick={() => handleSubNavClick("following")}
                className={
                  (activeTab === 'following')
                    ? "user-profile__tabs-tab user-profile__tabs-tab--active"
                    : "user-profile__tabs-tab"
                }
              >
                Following ({userInfo?.favorites?.length})
              </div>
              <div
                onClick={() => handleSubNavClick("checkins")}
                className={
                  (activeTab === 'checkins')
                    ? "user-profile__tabs-tab user-profile__tabs-tab--active"
                    : "user-profile__tabs-tab"
                }
              >
                Guestlist
              </div>
            </section>

            {(activeTab === 'reqDetails') && (
              <div className="container_content_user">
                <div className="content_following">
                  <div className="card_following" style={{ width: '100%' }}>
                    <div style={{color: '#FFFFFF'}}>Name:</div>
                    <p style={{color: '#FFFFFF', }}>{requestData?.requesterFirstName + " " + requestData?.requesterLastName}</p>
                  </div>
                  <div className="card_following" style={{ width: '100%' }}>
                    <div style={{color: '#FFFFFF'}}>Email Address:</div>
                    <p style={{color: '#FFFFFF', }}>{requestData?.requesterEmail || 'N/A'}</p>
                  </div>
                  <div className="card_following" style={{ width: '100%' }}>
                    <div style={{color: '#FFFFFF'}}>Phone Number:</div>
                    <p style={{color: '#FFFFFF', }}>{requestData?.requesterPhone || 'N/A'}</p>
                  </div>
                  <div className="card_following" style={{ width: '100%' }}>
                    <div style={{color: '#FFFFFF'}}>Subscription Plan:</div>
                    <p style={{color: '#FFFFFF', }}>{requestData?.subscriptionPlan || 'N/A'}</p>
                  </div>
                  <div className="card_following" style={{ width: '100%' }}>
                    <div style={{color: '#FFFFFF'}}>Billing Cycle:</div>
                    <p style={{color: '#FFFFFF', }}>{requestData?.billingCycle || 'N/A'}</p>
                  </div>
                  <div className="card_following" style={{ width: '100%' }}>
                    <div style={{color: '#FFFFFF'}}>LinkedIn Profile:</div>
                    <p style={{color: '#FFFFFF', }}>{requestData?.linkedinUrl || 'N/A'}</p>
                  </div>
                  <div className="card_following" style={{ width: '100%' }}>
                    <div style={{color: '#FFFFFF'}}>Date Sent:</div>
                    <p style={{color: '#FFFFFF', }}>{requestData?.created_at ? formatDate(requestData?.created_at) : 'N/A'}</p>
                  </div>
                  
                </div>
                

                <div style={{ width: '100%' }}>
                    <h1 style={{
                      color: '#FFFFFF',
                      paddingTop: 10,
                      paddingBottom: 12
                    }}>Preferences</h1>
                    
                    {requestData?.preferences?.map((item, k) => (
                      <span
                        key={k}
                        style={{ display: 'inline-block', padding: '8px', background: '#000000', color: '#FFFFFF', margin: '0 6px 6px 0', borderRadius: '4px' }}
                      >
                        {item}
                      </span>
                    ))}
                  </div>
              </div>
            )}

            {(activeTab === 'favEvents') && (
              <div className="container_content_user">
                {userInfo?.saved?.map((item, index) => (
                  <div className="card_favorites" key={index}>
                    <div
                      style={{ backgroundImage: `url(${item.image})` }}
                    ></div>

                    <div>
                      <h1>{item.name}</h1>
                    </div>

                    <div>
                      <p>
                        <CiLocationOn />
                        {item.address}
                      </p>

                      <p>
                        <CiClock2 />
                        <p> {formatHours(item.hours)}</p>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {(activeTab === 'pastEvents') && (
              <div className="container_content_user">
                {pastEventsList.map((item, index) => (
                  <div className="card_favorites-events" key={index}>
                    <div
                      style={{
                        backgroundImage: `url(${
                          Array.isArray(item.image_urls)
                            ? item.image_urls[0]
                            : item.image_urls
                        })`,
                      }}
                    ></div>
                    <div>
                      <h1>{item.name}</h1>
                      <p>
                        <CiLocationOn
                          style={{ color: "rgba(255, 255, 255, 0.7)" }}
                        />
                        {item.address}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <p>
                          <CiClock2
                            style={{ color: "rgba(255, 255, 255, 0.7)" }}
                          />
                          {formatHours(item.hours)}
                        </p>
                        <Link to={item.url}>
                          <SlShare size={24} color="#fff" />
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {(activeTab === 'following') && (
              <div className="container_content_user">
                <div className="content_following">
                  {userInfo?.favorites?.map((item) => (
                    <div key={item.id} className="card_following" style={{ width: '100%' }}>
                      <img src={item.image} alt="Imagem" />

                      <h1>{item.name}</h1>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {(activeTab === 'checkins') && (
              <div className="container_content_user">
                <div style={{ width: '100%' }}>
                  <table className="moderators__table" style={{ width: '100%' }}>
                    <thead className="moderators__table-top">
                      <tr className="moderators__table-top-headings">
                        {headings.map((heading, index) => (
                          <th
                            className="moderators__table-top-headings-heading"
                            key={index}
                            scope="col"
                          >
                            {heading}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody className="moderators__table-info">
                      {userCheckIns?.map((item) => (
                        <tr
                          className="moderators__table-info-info"
                          key={item._id}
                          style={{ width: '100%' }}
                        >
                          <td className="moderators__table-info-info-text">
                            {item?.venue_name}
                          </td>
                          <td className="moderators__table-info-info-text">
                            {formatDate(item?.created_at)}
                          </td>
                          <td className="moderators__table-info-info-text">
                            {formatTime(item?.created_at)}
                          </td>
                          <td className="moderators__table-info-info-text">
                            {renderRatingStars(item?.review?.rating)}
                          </td>
                          <td className="moderators__table-info-info-text moderators__table-info-info-text--status-box">
                            <p>{item?.review?.comments}</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </section>
        </article>
      </div>
    </main>
  );
}

export default MembershipRequestDetails;
