import styled from "styled-components";

export const ContainerTextArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5vw;

  > div:nth-child(1) {
    display: flex;
    padding: 0px 16px;
    align-items: center;

    > label {
      color: #ffffff;
      font-weight: bold;
    }
  }
`;

export const StyledTextArea = styled.textarea`
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 13px 16px;
  min-height: 200px;

  font-family: "Inter", sans-serif;

  width: 100%;
`;

export const Label = styled.label`
  color: #ffffff;
  font-weight: bold;
`;


export const ErrorMessage = styled.p`
  color: red;
  padding: 0vw 2vw;
  font-size: 0.8em;
`;
