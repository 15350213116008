import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebaseConfig";
import { isAfter } from "date-fns";

export default function ProtectedRoutes({ children }) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    // const twoFactorAuth = localStorage.getItem("2FA@ZonaAdmin");

    // if (!twoFactorAuth) {
    //   auth.signOut().then(() => {
    //     setVerified(null);
    //     setLoading(false);
    //   });
    //   return;
    // }

    // const { verified, expires } = JSON.parse(twoFactorAuth);

    // if (isAfter(Date.now(), expires)) {
    //   auth.signOut().then(() => {
    //     setVerified(null);
    //     setLoading(false);
    //   });
    //   return;
    // }

    // setVerified(verified);
    // setLoading(false);

    setLoading(false);
    setVerified(true);
  }, [navigate]);

  if (loading) return null;

  if (verified === null) return <Navigate to="/signin" />;

  // if (!verified) return <Navigate to="/two-factor-auth" />;

  return children;
}
