import styled from "styled-components";
import {keyframes} from "@emotion/react";

export const ContainerTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .table-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

  }

  table {
    width: 100%;
    border-collapse: collapse;
    tr {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }
    td {
      font-size: 0.9rem;
      font-weight: 200;
      padding: 12px 10px;
      text-align: center;
      color: #ffffff;
      max-width: 150px;  
      overflow: hidden;  
      text-overflow: ellipsis; 
      white-space: nowrap;
    }
    th {
      font-size: 1.1rem;
      font-weight: 300;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding: 12px 10px;
      color: #ffffff;
      text-align: center;
      width: 150px;  
      overflow: hidden; 
      text-overflow: ellipsis; 
      white-space: nowrap; 
      span {
        margin-left: 5px;
      }
    }
  }`

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  > h1 {
    font-size: 2rem;
    font-weight: 400;
    color: #ffffff;
  }
  > div:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 20px;

  }
`;

export const ContainerSorts = styled.div`
  display: flex;
  gap: 12px;
  > button {
    background-color: rgba(246, 246, 250, 0.1);
    border-radius: 40px;
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 0.5vw 1vw;
    margin-right: 1vw;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 200;

    width: max-content;
    
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
    &:active {
      background-color: rgba(2, 2, 4, 0.1);
    }
  }
`;

export const ContainerActions = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

//
// export const ButtonActions = styled.button`
//   border: none;
//   background: none;
//   cursor: pointer;
//   margin-right: 8px; /* Adjust spacing between actions */
// `;

export const TrashIcon = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;

  &:hover {
    transform: scale(1.2);
    color: red; /* Changes color on hover */
  }
`

export const ButtonActions = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 8px;
`;

export const Status = styled.td`
  color: ${({ status }) => {
    switch (status.toLowerCase()) {
      case "open":
      case "new":
        return "rgba(255, 255, 0)";
      case "declined":
      case "expired":
      case "canceled":
      case "denied":
        return "rgba(231, 76, 60, 1)";
      case "active":
      case "approved":
      case "solved":
      case "accepted":
        return "rgba(46, 204, 113, 1)";
      case "inactive":
        return "rgba(231, 76, 60, 1)";
      case "pending":
        return "rgba(241, 196, 15, 1)";
      case "claiming":
        return "rgb(212, 238, 96)";
      default:
        return "";
    }
  }} !important;
`;

export const ContainerTabs = styled.div`
  display: flex;
  margin-bottom: 32px;
  > div {
    display: flex;
    justify-content: space-around;
    width: 100%;
    > button {
      border: none;
      background: transparent;
      font-size: 18px;
      color: rgba(255, 255, 255, 1);
      font-weight: 600;
      line-height: 21.6px;
      padding: 0px 12px 8px 12px;
      width: 100%;
      cursor: pointer;
      &.active-tab {
        border-bottom: 1px solid rgba(167, 41, 245, 1);
      }
    }
  }
`;

export const ButtonExportData = styled.button`
  background-color: transparent;
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 400;
  border-radius: 8px;
  border: 2px solid #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 16px 24px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

export const ContainerCustomButton = styled.div`
  > button {
    background: linear-gradient(271.14deg, #7229f5 1.5%, #0a3fd6 98.6%);
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: 400;
    border-radius: 8px;
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 16px 24px 16px 24px;
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
`;


export const InputSearch = styled.input`
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    outline: none;
    border: none;
    border-radius: 8px;
    padding: 13px 16px;
    min-height: 38px;
  user-select: none;



      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.1);
      color: #ffffff;
      font-size: 1.1rem;
      font-weight: 400;
      border-radius: 8px;
      border: none;
      padding: 16px 24px 16px 24px;
      cursor: pointer;
`

export const CustomerButtons = styled.div`
  display: flex;
  justify-content: space;
  gap: 10px;
  user-select: none; /* Prevents text selection */
`


export const ContainerModifyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 10px 0;

  > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
  }
`;

// eslint-disable-next-line no-undef
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Styled component for the loading spinner
export const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
  margin: 20px auto; // Centers the spinner
`;

// You can also export a custom message for no records.
export const NoRecordsMessage = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 16px;
  color: #777; // Adjust the color as needed
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 20px 0;
`;
