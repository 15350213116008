 import "./EditEvent.scss";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Calendar from "react-calendar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import { FiCheck, FiX } from "react-icons/fi";

import checkMark from "../../assets/images/icons/check-mark.svg";
import chevronLeft from "../../assets/images/icons/chevron-left.svg";
import bin from "../../assets/images/icons/delete.svg";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import { HoursOfOperation } from "../../components/HoursOfOperation";
import Image from "../../components/Image";
import InputForm from "../../components/InputForm/InputForm";
import SelectForm from "../../components/SelectForm/SelectForm";
import SideBar from "../../components/SideBar/SideBar";
import TopNav from "../../components/TopNav/TopNav";
import api from "../../services/api";
import parseMinutesToHourString from "../../utils/parseMinutesToHourString";
import * as Yup from "yup";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import CustomDatePicker from "../../components/DataPicker/DataPicker";
import parseDate from "../../utils/parseDate";
import { format } from "date-fns";

const EditEvent = () => {
  const { eventID } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [event, setEvent] = useState({});
  const [newEventName, setNewEventName] = useState("");

  // const [updateHours, setUpdateHours] = useState("");
  const [updatePrice, setUpdatePrice] = useState("");

  const [newEventDate, setNewEventDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const [updateLocation, setUpdateLocation] = useState("");
  const [updatedCat, setUpdatedCat] = useState([]);
  const [updatePreferences, setUpdatePreferences] = useState([]);
  const [updatedDress, setUpdatedDress] = useState("");
  const [updatedMusic, setUpdatedMusic] = useState([]);

  const [hours, setHours] = useState();


  const [updatedAbout, setUpdatedAbout] = useState("");

  const [images, setImages] = useState([{ id: v4() }])

  const [updateDate, setUpdateDate] = useState("");
  const [selectedVanues, setSelectedVanues] = useState();

  const [updateSpecials, setUpdateSpecials] = useState("");

  const [errors, setErrors] = useState({});

  const validationSchema = Yup.object().shape({
    // newEventName: Yup.string().required("Name is required"),
    // updatePrice: Yup.string().required("Price is required"),
    // updateDate: Yup.string().required("Date is required"),
    // updatedAbout: Yup.string().required("About is required"),
    // updatedCat: Yup.array().of(Yup.string()).min(1, "Select at least one Category"),
    // // updatePreferences: Yup.array().of(Yup.string()).min(1, "Select at least one Preferences"),
    // updatedMusic: Yup.array().of(Yup.string()).min(1, "Select at least one Music"),
    // updateLocation: Yup.string().required("Select one "),
    // updatedDress: Yup.string().required("Select one"),
    // updateSpecials: Yup.string().required('Specials is required'),

  });

  useEffect(() => {
    const getEvent = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/events/${eventID}`);
        setEvent(response.data);
        setLoading(false);

      } catch (err) {
        console.log(`error fetching event. this was the error: ${err}`);
      }
    };
    getEvent();
  }, [eventID]);


  const [deleteModal, setDeleteModal] = useState(false);
  const [eventToDelete, setEventToDelete] = useState({});

  async function handleDeleteEvent(event) {
    if (event.status === "inactive") {
      const { data } = await api.put(`/events/${eventID}`, { status: "active" });

      setEvent(data);
    } else {
      setDeleteModal(true);
      setEventToDelete(event);
    }
  }

  async function handleDelete(_id) {
    try {
      await api.delete(`/events/${eventID}`);

      setDeleteModal(false);
      navigate("/events");
    } catch (err) {
      console.log(`failed to delete events. this is the error: ${err}`);
    }
  }


  const handleCategoryChange = (selectedOptions) => {
    const selectedValues = Array.isArray(selectedOptions)
      ? selectedOptions.map((option) => option.value)
      : [];

    setUpdatedCat(selectedValues);
  };

  const handleDressChange = (selectedOptions) => {
    setUpdatedDress(selectedOptions ? selectedOptions.value : "");
  };

  const handleMusicChange = (selectedOption) => {
    const selectValues = Array.isArray(selectedOption)
      ? selectedOption.map((option) => option.value)
      : [];

    setUpdatedMusic(selectValues);
  };

  const handlePreferencesChange = (selectedOptions) => {
    const selecterdValues = Array.isArray(selectedOptions)
      ? selectedOptions.map((option) => option.value)
      : [];

    setUpdatePreferences(selecterdValues);
  };

  const handleLocationChange = (selectedOptions) => {
    setUpdateLocation(selectedOptions ? selectedOptions.value : "");
  };

  const [listBusiness, setListBusiness] = useState([]);


  useEffect(() => {
    const getBusiness = async () => {
      try {
        const response = await api.get(`/venues`, {
          params: {
            records: 99999,
            page: 0
          }
        });
        setListBusiness(response.data.data);
      } catch (error) {
        console.log(`venues get request didn't work. error: ${error}`);
      }
    };
    getBusiness();
  }, []);

  const optionsVenus = listBusiness.map((business) => ({
    value: business._id,
    label: business.name,
  }));

  const handleCategoryVanues = (selectedOptions) => {
    setSelectedVanues(selectedOptions);
  };

  const venueId = optionsVenus.find(
    (option) => option.value === event.venue_id
  );

  useEffect(() => {
    if (venueId && !selectedVanues) {
      setSelectedVanues(venueId);
    }
  }, [venueId, selectedVanues]);



  const toTimestamp = (date) => {
    if (!date) return null;
    const d = new Date(date);
    return d.getTime();
  };


  function parseHourStringToMinutes(hourString) {
    if (typeof hourString !== "string") {
      throw new TypeError('Expected a string in the format "HH:MM"');
    }

    const [hours, minutes] = hourString.split(":").map(Number);

    if (isNaN(hours) || isNaN(minutes)) {
      throw new Error('Invalid time format. Expected "HH:MM"');
    }

    return hours * 60 + minutes;
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      await validationSchema.validate({
        newEventName,
        updatePrice,
        updateDate,
        updatedAbout,
        updatedCat,
        updatePreferences,
        updatedMusic,
        updateLocation,
        updatedDress,
        updateSpecials,
      }, { abortEarly: false });

      const formattedDate = updateDate
        ? format(updateDate, "yyyy-MM-dd")
        : "";

      const fromHours =
        typeof hours.from === "string"
          ? parseHourStringToMinutes(hours.from)
          : hours.from;
      const toHours =
        typeof hours.to === "string"
          ? parseHourStringToMinutes(hours.to)
          : hours.to;


      const editedUser = {
        name: newEventName,
        tags: updatedCat,
        venue_id: selectedVanues?.value,
        price: updatePrice,
        date: formattedDate,
        hours: {
          from: fromHours,
          to: toHours,
        },
        dress: updatedDress,
        music: updatedMusic,
        location: updateLocation,
        preferences: updatePreferences,
        description: updatedAbout,
        specials: updateSpecials
      };

      await api.put(`/events/${eventID}`, editedUser);


      toast.success('Event Edited Successfully!');


      navigate("/events");
    } catch (error) {
      console.log(error)
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
        toast.error(`Error edit event. Please try again, ${error.message}`);

      } else {
        console.error("Error edit user:", error);
      }

    };
  };

  const days = useMemo(() => {
    return [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
  }, []);

  useEffect(() => {
    setNewEventName(event.name || '');

    setUpdateDate(parseDate(event.date));

    setUpdatePrice(event.price || '');

    setUpdatedMusic(event.music || '');
    setUpdatedDress(event.dress || '');

    setUpdatePreferences(event.preferences || '');

    setUpdateLocation(event.location || '');
    setUpdatedCat(event.tags || '');

    setUpdatedAbout(event.description || '');
    setUpdateSpecials(event.specials || '')


    if (event?.hours) {
      setHours({
        from: parseMinutesToHourString(event.hours.from),
        to: parseMinutesToHourString(event.hours.to),
      });
    } else {
      setHours({ from: "", to: "" });
    }

    if (event?.image?.length) {
      const imgs = event.image.map((img, index) => {
        return {
          id: img,
          url: event.image_urls[index],
        };
      });

      setImages([...imgs, { id: v4() }]);
    }

    const parsedHours = Object.entries(event.hours || {}).reduce(
      (acc, [key, value]) => {
        const hour = {
          // day: key,
          hours: value,
        };

        acc.push(hour);

        return acc;
      },
      []
    );
  }, [event, days]);

  const handleImageAdd = useCallback(
    async (events) => {
      if (!event?._id) return;

      const file = events.target.files[0];

      const formData = new FormData();

      formData.append("images", file);

      const { data } = await api.post(`/events/images/${event._id}`, formData);
      const recentImageAdded = data[0];


      const image = { id: recentImageAdded };

      setImages((oldState) => [...oldState, image]);

      alert("Image uploaded successfully!");
    },
    [event]
  );

  const handleImageRemove = useCallback(
    async (_id) => {
      if (!event?._id) return;

      await api.delete(`/events/images/${event._id}`, {
        params: {
          images: [_id],
        },
      });

      setImages((oldState) => {
        const updatedState = oldState.filter((i) => i._id !== _id);

        return updatedState;
      });

      alert("Image DELETED successfully!");
    },
    [event]
  );

  const [filters, setFilters] = useState();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [dressCodeOptions, setDressCodeOptions] = useState([])
  const [musicOptions, setMusicOptions] = useState([])
  const [locationsOptions, setLocationOptions] = useState([])

  useEffect(() => {
    async function getFilters() {
      try {
        const response = await api.get('filters')

        const tags = response.data.tags;
        const dressCode = response.data.dress_code
        const music = response.data.music;
        const location = response.data.location;

        const formattedTags = tags.map(tag => ({ value: tag, label: tag }));
        const formattedDressCode = dressCode.map(tag => ({ value: tag, label: tag }));
        const formattedMusic = music.map(tag => ({ value: tag, label: tag }));
        const formatteLocation = location.map(tag => ({ value: tag, label: tag }));


        setCategoryOptions(formattedTags);
        setDressCodeOptions(formattedDressCode);
        setMusicOptions(formattedMusic)
        setLocationOptions(formatteLocation)

        setFilters(response.data)
      } catch (err) {
        console.log(err)
      }
    }

    getFilters()
  }, [])



  const handleDateChange = (date) => {
    setUpdateDate(date);

  };

  const defaultHours = {
    from: hours?.from || "",
    to: hours?.to || "",
  };

  return (
    <main>
      <TopNav />
      <div className="main-container">
        <SideBar />
        {deleteModal && (
          <DeleteModal
            handleDelete={handleDelete}
            setDeleteModal={setDeleteModal}
            modToDelete={eventToDelete}
            tableName={"EVENTS"}
            title={
              <p>
                Are you sure?
              </p>
            }
            message={
              <p>
                You can activate this event later...
              </p>
            }
            button="Yes"
          />
        )}
        <article
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <section className="edit-event">
            <div className="edit-event__top">
              <div className="edit-event__top-left">
                <Link to="/events">
                  <img
                    className="edit-event__top-left-icon"
                    src={chevronLeft}
                    alt="Left pointing chevron"
                  ></img>
                </Link>
                <h1 className="edit-event__top-left-heading">Editing Event</h1>
              </div>
              <button
                className="edit-event__top-button"
                onClick={() => handleDeleteEvent(event)}
              >
                <p className="edit-event__top-button-text">
                  {event.status === "inactive"
                    ? "Activate event"
                    : "Deactivate event"}
                </p>

                {event.status === "inactive" ? (
                  <FiCheck color="#fff" />
                ) : (
                  <FiX color="#fff" />
                )}
              </button>
            </div>

            {loading ? (
              <>
                <LoadingPage />
              </>
            ) : (
              <>
                <form onSubmit={handleFormSubmit} className="edit-event__form">
                  <section className="edit-event__form--left">
                    <div>
                      <div>
                        <InputForm
                          placeholder="Enter name"
                          label="Name"
                          type="text"
                          id="venueName"
                          name="name"
                          value={newEventName}
                          onChange={(event) => setNewEventName(event.target.value)}
                          error={errors.newEventName}
                        />
                      </div>

                      <div>
                        <SelectForm
                          error={errors.updatedCat}
                          isMulti
                          label="Category"
                          options={categoryOptions}
                          value={categoryOptions.filter((option) =>
                            updatedCat.includes(option.value)
                          )}
                          onChange={handleCategoryChange}
                        />
                      </div>
                    </div>

                    <div>
                      <div>
                        <SelectForm
                          label="Venue"
                          options={optionsVenus}
                          value={selectedVanues}
                          onChange={handleCategoryVanues}
                        />
                      </div>

                      <div>
                        <InputForm
                          label="Price"
                          type="text"
                          id="venuePrice"
                          name="price"
                          value={updatePrice}
                          onChange={(event) => setUpdatePrice(event.target.value)}
                          placeholder="Example: $20"

                          error={errors.updatePrice}

                        />
                      </div>
                    </div>

                    <div>
                      <div>
                        <div
                          className="edit-event__form-block-group-container"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            gap: "0.5vw"
                          }}
                        >
                          <label style={{ padding: "0px 16px" }}>
                            Date
                          </label>
                          {/* <input
                            style={{ width: "100%" }}
                            type="date"
                            id="eventDate"
                            name="eventDate"
                            className="edit-event__form-block-group-input"
                            value={formatDateForInput(updateDate)}
                            onChange={handleChange}
                          /> */}


                          <CustomDatePicker timestamp={updateDate} onDateChange={handleDateChange} />
                        </div>
                      </div>

                      <div style={{ display: "flex", gap: "0.5vw" }}>
                        <label style={{ padding: "0px 16px" }}>Hours</label>
                        <div className="add-venue__hours-of-operation">
                          <div className="hours-operation">
                            <HoursOfOperation
                              defaultValues={{
                                day: "",
                                hours: defaultHours,
                              }}
                              selectDay={false}
                              // onChangeDay={({ value }) => {
                              //     setUpdatedHour((oldState) => {
                              //         if (!oldState?.hours) {
                              //             return { day: value };
                              //         }

                              //         return { ...oldState, day: value };
                              //     });
                              // }}
                              onChangeTime={({ value, type }) => {
                                setHours((oldState) => {
                                  const newState = { ...oldState };

                                  if (type === "from") {
                                    newState.from = value;
                                  } else if (type === "to") {
                                    newState.to = value;
                                  }

                                  return newState;
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div>
                        <SelectForm
                          error={errors.updatedDress}
                          label="Dress-code"
                          name="dress"
                          options={dressCodeOptions}
                          value={
                            updatedDress
                              ? dressCodeOptions.filter((option) =>
                                updatedDress.includes(option.value)
                              )
                              : []
                          }
                          onChange={handleDressChange}
                          getOptionValue={(option) => option.value}
                        />
                      </div>
                      <div>
                        <SelectForm
                          error={errors.updatedMusic}
                          label="Music"
                          isMulti
                          options={musicOptions}
                          value={musicOptions.filter((option) =>
                            updatedMusic.includes(option.value)
                          )}
                          onChange={handleMusicChange}
                        />
                      </div>
                    </div>

                    <div>
                      <div>
                        <SelectForm
                          error={errors.updateLocation}

                          label="Location"
                          name="location"
                          options={locationsOptions}
                          value={
                            updateLocation
                              ? locationsOptions.filter((option) =>
                                updateLocation.includes(option.value)
                              )
                              : []
                          }
                          onChange={handleLocationChange}
                        // getOptionValue={(option) => option.value}
                        />
                      </div>
                    </div>

                    <div className="container-about">
                      <label
                        htmlFor="venueDescription"
                        className="venue-info__form-group-field-label"
                      >
                        About
                      </label>
                      <textarea
                        rows={4}
                        id="venueDescription"
                        name="description"
                        className="venue-info__form-group-about-input"
                        value={updatedAbout}
                        onChange={(event) => setUpdatedAbout(event.target.value)}
                      // placeholder={specVenue.about || "Enter description"}
                      />
                    </div>

                    <div className="add-venue__form-block">
                      <div
                        className="add-venue__form-block-group"
                        style={{ width: "100%" }}
                      >
                        <label
                          htmlFor="venueAbout"
                          className="venue-info__form-group-field-label"
                        >
                          More Information (Specials)
                          <span className='error-message'>{errors.updateSpecials}</span>
                        </label>
                        <textarea
                          placeholder='Description of the venue'
                          rows={4}
                          id="venueAbout"
                          name="description"
                          className="venue-info__form-group-about-input"
                          value={updateSpecials}
                          onChange={(event) => setUpdateSpecials(event.target.value)}
                        // placeholder={specVenue.about || "Enter description"}
                        />
                      </div>
                    </div>

                    <div className="edit-event__form-photo">
                      {images.map((img) => {
                        return (
                          <Image
                            key={img.url}
                            onAdd={handleImageAdd}
                            onRemove={() => handleImageRemove(img.id)}
                            defaultValue={img.url}
                          />
                        );
                      })}
                    </div>


                  </section>
                </form>

                <div className="container-buttons">
                  <button type="submit" className="button_spotilight">
                    <Link
                      to={`/spotlight/event/${eventID}`}
                      style={{ display: "flex" }}
                    >
                      <p className="edit-event__form-button-text">
                        Spotlight event
                      </p>
                      <img src={checkMark} alt="a checkmark"></img>
                    </Link>
                  </button>

                  <button type="submit" className="edit-event__form-button" onClick={handleFormSubmit}>
                    <p className="edit-event__form-button-text">
                      Update Information
                    </p>
                    <img src={checkMark} alt="a checkmark"></img>
                  </button>
                </div>
              </>
            )}
          </section>
        </article>
      </div>
    </main>
  );
}

export default EditEvent;
