import '../../styles/CalendarStyles.css';
import './AddUsers.scss';

import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import checkMark from '../../assets/images/icons/check-mark.svg';
import chevronLeft from '../../assets/images/icons/chevron-left.svg';
import FormDatePicker from '../../components/FormDatePicker/FormDatePicker';
import InputForm from '../../components/InputForm/InputForm';
import SelectForm from '../../components/SelectForm/SelectForm';
import SideBar from '../../components/SideBar/SideBar';
import TopNav from '../../components/TopNav/TopNav';
import api from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth } from '../../firebase/firebaseConfig';
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';

const AddUsers = () => {
  const navigate = useNavigate();


  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [birthday, setBirthday] = useState(new Date());
  const [gender, setGender] = useState();
  const [ig, setIg] = useState();

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    phone: Yup.string().required("Phone number is required"),
    email: Yup.string().email("Invalid email").required("Email is required").matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email format'),
    gender: Yup.string().required("Gender is required"),
    // ig: Yup.string().required("Instagram is required"),
  });

  const handleAddUserSubmit = async (event) => {
    event.preventDefault();

    try {
      await validationSchema.validate({
        firstName,
        lastName,
        phone,
        email,
        gender,
        // ig,
      }, { abortEarly: false });

      setLoading(true);


      // const userCredential = await createUserWithEmailAndPassword(
      //   auth,
      //   email,
      //   uuidv4()
      // )

      // if (!userCredential) {
      //   alert('Could not create firebase user!');
      //   return;
      // }

      // await sendPasswordResetEmail(auth, email);

      // const { uid } = userCredential.user;

      const newUser = {
        firstName,
        lastName,
        phone,
        email,
        birthday,
        gender,
        ig,
        admin: isAdmin
      }

      await api.post("/users/firebase", newUser);

      setFirstName("");
      setLastName("");
      setPhone("");
      setEmail("");
      setGender("");
      setIg("");

      setLoading(false);

      toast.success('User Added successfully!');

      navigate("/users");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message === 'This email is already in use!') {
        toast.error('This email is already in use!');
      }

      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
        toast.error(`Error Adding User. Please try again, ${error.message}`);

      } else {
        console.error("Error adding user:", error);
      }
      setLoading(false);
    }
  }


  const handleBirthdaySelect = (selectedBirthday) => {

    setBirthday(selectedBirthday)
  };


  const optionsGender = [{ value: "Male", label: "Male" }, { value: "Female", label: "Female" }]

  const [isAdmin, setIsAdmin] = useState(null)


  const handleAdminChange = (selectedOption) => {
    setIsAdmin(selectedOption ? selectedOption.value : "")
  }


  const optionsAdmin = [{ label: 'No', value: false }, { label: 'Yes', value: true }]


  return (
    <main>
      <TopNav />
      <div className="main-container">
        <SideBar />
        <article
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <section className="add-user">
            <div className="add-user__top">
              <Link to="/users">
                <img
                  className="add-user__top-icon"
                  src={chevronLeft}
                  alt="chevron pointing left"
                ></img>
              </Link>
              <h1 className="add-user__top-heading">Adding new user</h1>
            </div>

            <form onSubmit={handleAddUserSubmit} className="add-user__form">
              <section className="add-user__form--left">
                <div>
                  <div>
                    <SelectForm
                      label="Admin"
                      options={optionsAdmin}
                      value={optionsAdmin.find(option => option.value === isAdmin)}
                      onChange={handleAdminChange}
                    />

                  </div>
                </div>

                <div>
                  <div>
                    <InputForm
                      placeholder="Name"
                      label="Name"
                      type="text"
                      id="name"
                      name="name"
                      value={firstName}
                      onChange={(event) =>
                        setFirstName(event.target.value)
                      }
                      error={errors.firstName}
                    />

                  </div>

                  <div>
                    <InputForm
                      placeholder="Last Name"
                      label="Last Name"
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={lastName}
                      onChange={(event) =>
                        setLastName(event.target.value)
                      }
                      error={errors.lastName}
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <InputForm
                      placeholder="Phone"
                      label="Phone"
                      type="text"
                      id="phone"
                      name="phone"
                      value={phone}
                      onChange={(event) =>
                        setPhone(event.target.value)
                      }
                      error={errors.phone}
                    />
                  </div>
                  <div>
                    <InputForm
                      placeholder="Email"
                      label="Email"
                      type="text"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(event) =>
                        setEmail(event.target.value)
                      }
                      error={errors.email}
                    />
                  </div>
                </div>


                <div>
                  <div>
                    <SelectForm
                      label="Gender"
                      options={optionsGender}
                      value={optionsGender?.value}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        setGender(selectedOption.value);
                      }}
                      error={errors.gender}
                    />
                  </div>

                  <div>
                    <FormDatePicker onBirthdaySelect={handleBirthdaySelect} />

                  </div>
                </div>

                <div>
                  <div>
                    <InputForm
                      placeholder="Instagram"
                      label="Instagram"
                      type="text"
                      id="ig"
                      name="ig"
                      value={ig}
                      onChange={(event) =>
                        setIg(event.target.value)
                      }
                      error={errors.ig}
                    />
                  </div>
                </div>

                <button type="submit" className="add-user__form-button" disabled={loading} style={{ opacity: loading ? .5 : 1 }}>
                  <p className="add-user__form-button-text">Save Information</p>
                  <img src={checkMark} alt="a checkmark"></img>
                </button>
              </section>
            </form>
          </section>
        </article>
      </div>
    </main>
  );
}

export default AddUsers;
